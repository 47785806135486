<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      uploadedFiles: [],
      downloadFiles: {},
      uploadProcessing: false,
      applicationUploadedTemplateFiles: [],
    };
  },
  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "setModalDangerMessage",
      "setModalAlertMessage",
      "setModalInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
    ]),
    async getUploadedDocuments(companyID, rootFolder = undefined) {
      const options = {
        method: "GET",
        url: `${WebAPIUrl}/api/CompanyInfo/${companyID}`,
        params: {
          companyID,
          rootFolder,
        },
      };
      await axios(options)
        .then((result) => {
          this.uploadedFiles = result.data;
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
    async downloadFile(companyID, fileName) {
      await axios
        .get(`${WebAPIUrl}/api/CompanyInfo/${companyID}/${fileName}`, { responseType: "blob" })
        .then((result) => {
          var FILE = window.URL.createObjectURL(new Blob([result.data]));
          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          docUrl.style.position = "fixed";
          docUrl.target = "_blank";
          docUrl.download = fileName;
          //docUrl.setAttribute("download", fileName);
          document.body.appendChild(docUrl);
          docUrl.click();
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
    async sendFiles(params, files, isMultiple) {
      this.uploadProcessing = true;
      const form = new FormData();
      let fileName = "";

      // Multiple or Single File
      if (isMultiple) {
        for (var i = 0; i < files.length; i++) {
          const file = files[i];
          form.append("file", file.getRawFile());
        }
        fileName = files.map((file) => file.name).join(", ");
      } else {
        form.append("file", files);
        fileName = files.name || "";
      }

      return await axios
        .post(`${WebAPIUrl}/api/CompanyInfo/${params.companyID}`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params,
        })
        .then((result) => {
          this.statusCode = result.status;
          this.setInfoMessage(`${fileName} Uploaded Successfully!`);
          this.uploadProcessing = false;
          return result;
        })
        .catch((error) => {
          this.statusCode = error.status;
          this.setDangerMessage(`Error. ${error.response.data}`);
          this.uploadProcessing = false;
          return error;
        });
    },
    async deleteUploadedFile(companyID, fileName) {
      await axios
        .delete(`${WebAPIUrl}/api/CompanyInfo/${companyID}/${fileName}`)
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) this.setInfoMessage(result.data);
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getApplicationUploadedFilesTemplates(companyID) {
      await axios
        .get(`${WebAPIUrl}/api/CompanyInfo/files/${companyID}`)
        .then((result) => {
          const { data = [] } = result;
          this.applicationUploadedTemplateFiles = data;
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
  },
};
</script>
