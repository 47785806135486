<template>
  <div>
    <div class="example">
      <confirmation ref="Confirmation"></confirmation>
      <b-card class="dashboard-card shadow-sm pt-3">
        <div>
          <a
            v-for="(bread, index) in breadcrumbss"
            class="bread"
            :key="bread"
            href="javascript:void(0)"
            @click="goToBread(bread, index)"
            >{{ bread }}
            <span class="bread-diff">{{ index !== breadcrumbss.length - 1 ? "/" : "" }}</span></a
          >
        </div>
        <div class="upload">
          <upload
            :auto-upload="false"
            :batch="false"
            :files="files"
            @add="onAdd"
            @remove="removeData"
            :with-credentials="false"
            :multiple="true"
            :save-url="saveData"
            :remove-url="removeData"
          ></upload>
          <b-card>
            <div v-if="uploadedFiles.length">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th colspan="2">Name</th>
                      <th>Modified Date</th>
                      <th>Modified By</th>
                      <th>File Type</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="file in uploadedFiles" :key="file.Name">
                      <td>
                        <span
                          v-if="file.fileExtension == '.pdf'"
                          class="k-icon k-i-file-pdf"
                        ></span>
                        <span
                          v-if="file.fileExtension == '.txt'"
                          class="k-icon k-i-file-txt"
                        ></span>
                        <span v-if="file.isfolder === true" class="k-icon k-i-file"></span>
                        <span
                          v-if="file.fileExtension == '.docx' || file.fileExtension == '.doc'"
                          class="k-icon k-i-file-word"
                        ></span>
                        <span
                          v-if="file.fileExtension == '.xlsx'"
                          class="k-icon k-i-file-xls"
                        ></span>
                        <span v-if="file.fileExtension == '.jpg'" class="k-icon k-i-image"></span>
                      </td>
                      <td>
                        <a v-if="file.isfolder === true" @click="fileNavigation(file.Name)">{{
                          file.Name
                        }}</a>
                        <p v-else>{{ file.Name }}</p>
                      </td>
                      <td>{{ file.ModifiedDate }}</td>
                      <td>{{ file.ModifiedBy }}</td>
                      <td v-if="file.isfolder === true">Folder</td>
                      <td v-if="file.isfolder === false">
                        {{ file.fileExtension.replace(".", "") }}
                      </td>
                      <td colspan="2">
                        <kbutton
                          v-if="file.isfolder === false"
                          :icon="'download'"
                          :theme-color="'success'"
                          :fill-mode="'outline'"
                          :size="'small'"
                          @click="clickToDownloadFile(file.Name)"
                        ></kbutton>
                      </td>
                      <td>
                        <kbutton
                          v-if="file.isfolder === false"
                          :icon="'trash'"
                          :theme-color="'error'"
                          :fill-mode="'outline'"
                          :size="'small'"
                          @click="deleteFile(file.Name)"
                        >
                        </kbutton>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>
              <td>
                <div class="text-center">
                  <h4>Click the Select Files button to Upload a file</h4>
                </div>
              </td>
            </div>
          </b-card>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import { Upload } from "@progress/kendo-vue-upload";
import UploadDocumentMixin from "../../../mixins/UploadDocumentMixin.vue";
//import { BIconTrash } from "bootstrap-vue";
import { Button } from "@progress/kendo-vue-buttons";
import Confirmation from "../../../components/Common/Confirmation.vue";
import { bus } from "../../../main";
export default {
  components: {
    upload: Upload,
    //BIconTrash,
    Confirmation,
    kbutton: Button,
  },
  mixins: [UploadDocumentMixin],
  async created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });
    await this.getCurrentDocList();
    bus.$emit("showFullpageOverlay", {
      showLayover: false,
    });
  },
  data() {
    return {
      files: [],
      events: [],
      filePreviews: {},
      company: {},
      items: [],
      breadcrumbss: [],
      show: false,
    };
  },
  methods: {
    async getCurrentDocList(fileName) {
      this.company = JSON.parse(localStorage.getItem("currentCompany"));
      await this.getUploadedDocuments(this.company.companyID, fileName);
    },
    fileNavigation(fileName) {
      this.show = false;
      this.getCurrentDocList(fileName);
      if (this.breadcrumbss.length === 0) {
        this.breadcrumbss.push("Uploads", fileName);
      } else {
        this.breadcrumbss.push(fileName);
      }
    },
    async deleteFile(fileName) {
      this.$refs.Confirmation.show({
        title: "Delete the Selected File",
        message: `Are you sure you want to delete ${fileName}?`,
        okButton: "Yes",
        cancelButton: "No",
        exitFunction: () => {},
      }).then(async (result) => {
        if (result) {
          this.setInfoMessage("");
          await this.deleteUploadedFile(this.company.companyID, fileName);
          if (this.statusCode && this.statusCode === 200) this.getCurrentDocList();
        }
      });
    },
    async clickToDownloadFile(fileName) {
      await this.downloadFile(this.company.companyID, fileName);
    },
    onAdd(event) {
      this.files = event.affectedFiles;
    },
    async saveData() {
      bus.$emit("showFullpageOverlay", {
        showLayover: true,
      });

      let params = { companyID: this.company.companyID };
      let lastElement = this.items[this.items.length - 1];
      if (lastElement) {
        params["rootFolder"] = lastElement.text;
      }
      await this.sendFiles(params, this.files, true);
      if (this.statusCode && this.statusCode === 200) {
        this.files = [];
        if (lastElement) this.getCurrentDocList(params.rootFolder);
        else this.getCurrentDocList();
        bus.$emit("showFullpageOverlay", {
          showLayover: false,
        });
      }
    },
    removeData() {
      this.files = [];
    },
    handleAction(ev, itemId) {
      let expandedItems = this.expanded.slice();
      expandedItems = expandedItems.map((el) => el === false);
      expandedItems[itemId] = !this.expanded[itemId];
      this.expanded = expandedItems;
    },
    goToBread(filename, index) {
      if (filename === "Uploads") {
        this.getCurrentDocList();
        this.breadcrumbss = [];
        return false;
      } else {
        this.getCurrentDocList(filename);
        this.breadcrumbss.length = index + 1;
        return false;
      }
    },
  },
};
</script>
<style>
.example {
  display: flex;
  gap: 20px;
  margin-top: 4.3rem;
}
.upload {
  flex: 1 0 60%;
}
.example-config {
  flex: 1 0 30%;
}
a,
#trash {
  cursor: pointer;
  margin-left: 10px;
}
.bread:last-child,
.bread-diff {
  pointer-events: none;
  cursor: default;
  color: black;
}
.bread {
  text-decoration: none;
}
</style>
